import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Col, Row } from "react-bootstrap";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Heading24, Heading32, Paragraph16 } from "../../styles/PageStyles";
import {
  ProjectsContainer,
  StyledSlider,
  ProjectCard,
  ServicesAndSolutionContainer,
  CarouselItem,
} from "./ProjectsContent.styled";
import useWindowSize from "../../components/shared/WindowDimensions";
import { useState } from "react";

const ProjectsContent = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <IoMdArrowDropright
        className="directional-arrow left"
        onClick={onClick}
        size={100}
      />
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <IoMdArrowDropleft
        className="directional-arrow right"
        onClick={onClick}
        size={100}
      />
    );
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "projects" } }) {
        frontmatter {
          projects {
            title
            id
            tags {
              tag
            }
            description
            defaultImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            animationDelay
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              title
              hidden
              information {
                separate
                title
                description
              }
            }
          }
          servicesAndSolutionEPCC {
            hidden
            title
            image {
              publicURL
            }
          }
          servicesAndSolutionOO {
            hidden
            title
            image {
              publicURL
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = data.markdownRemark;
  const { width } = useWindowSize();
  const [expanded, setExpanded] = useState(false);

  return (
    <ProjectsContainer fluid>
      <Row className="mb">
        {frontmatter?.projects?.map((project, index) => (
          <Col className="project-card-col" key={index} lg={4} md={12} xs={12}>
            <ProjectCard
              data-aos="fade-down"
              data-aos-delay={project?.animationDelay}
            >
              <Link to={`#${project?.id}`}>
                <GatsbyImage
                  image={getImage(project?.defaultImage)}
                  alt={project?.title}
                  className="project-img"
                />
                <div className="project-name">
                  <Heading24>{project?.title}</Heading24>
                </div>
              </Link>
            </ProjectCard>
          </Col>
        ))}
      </Row>
      {!frontmatter?.servicesAndSolutionEPCC?.hidden && (
        <Row>
          <ServicesAndSolutionContainer>
            <div>
              <Heading32 className="pt-50">
                {frontmatter?.servicesAndSolutionEPCC?.title}
              </Heading32>
              <div className="services-and-solution-img">
                <img
                  src={frontmatter?.servicesAndSolutionEPCC?.image?.publicURL}
                  alt={frontmatter?.servicesAndSolutionEPCC?.title}
                />
              </div>
            </div>
          </ServicesAndSolutionContainer>
        </Row>
      )}
      {!frontmatter?.servicesAndSolutionOO?.hidden && (
        <Row className="pt-50">
          <ServicesAndSolutionContainer>
            <div>
              <Heading32 className="pt-50">
                {frontmatter?.servicesAndSolutionOO?.title}
              </Heading32>
              <div className="services-and-solution-img">
                <img
                  src={frontmatter?.servicesAndSolutionOO?.image?.publicURL}
                  alt={frontmatter?.servicesAndSolutionOO?.title}
                />
              </div>
            </div>
          </ServicesAndSolutionContainer>
        </Row>
      )}
      {frontmatter?.projects?.map((project, index) => (
        <Row key={index} id={`${project?.id}`} className="project pt-20">
          <div>
            <Heading32>{project?.title}</Heading32>
          </div>
          <Heading24 className="project-tags">
            {project?.tags?.map((item, subIndex) => (
              <>
                {item?.tag} <span key={subIndex}>&nbsp;</span>
              </>
            ))}
          </Heading24>
          <Paragraph16>
            <ReactMarkdown>{project?.description}</ReactMarkdown>
          </Paragraph16>

          <StyledSlider {...carouselSettings}>
            {project?.images.map(
              (item) =>
                !item?.hidden && (
                  <>
                    <div
                      key={item?.id}
                      className={`item-container ${expanded ? "expanded" : ""}`}
                    >
                      <CarouselItem>
                        <div className="separate-info">
                          <div className="separate-info-inner">
                            <button className="questionMarkIcon">?</button>
                            <div className={`separate-description`}>
                              {item?.information?.map(
                                (info, infoIndex) =>
                                  info?.separate && (
                                    <Paragraph16 key={infoIndex}>
                                      <span className="description-title">
                                        {info?.title}:{" "}
                                      </span>
                                      {`${info?.description}`}
                                    </Paragraph16>
                                  )
                              )}
                            </div>
                          </div>
                        </div>

                        <GatsbyImage
                          image={getImage(item?.image)}
                          alt={item?.title}
                          className="carousel-img"
                          draggable={false}
                        />

                        <div className="description-wrapper">
                          <div className={`carousel-item-title`}>
                            <Heading24 className="title">
                              {item?.title}
                            </Heading24>
                          </div>
                          <div className={`carousel-item-hover`}>
                            <div className="heading">
                              <Heading24 className="title">
                                {item?.title}
                              </Heading24>
                            </div>

                            <div className="description">
                              {item?.information?.map(
                                (info, infoIndex) =>
                                  !info?.separate && (
                                    <Heading24
                                      key={infoIndex}
                                    >{`${info?.title}: ${info?.description}`}</Heading24>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </CarouselItem>
                      <div className="description-wrapper">
                        {width < 600 && !expanded && (
                          <button
                            onClick={() => setExpanded(true)}
                            className={`see-more `}
                          >
                            See more
                          </button>
                        )}
                        {width < 600 && expanded && (
                          <button
                            onClick={() => setExpanded(false)}
                            className={`see-more `}
                          >
                            See less
                          </button>
                        )}
                        <p
                          className={`mobile-description ${
                            !expanded ? "truncated" : ""
                          }`}
                        >
                          {width < 600 &&
                            item?.information?.map(
                              (info, infoIndex) =>
                                info?.separate && (
                                  <>
                                    <span
                                      key={infoIndex}
                                      className="description-paragraph"
                                    >
                                      <span className="description-title">
                                        {info?.title}:{" "}
                                      </span>
                                      {info?.description}
                                    </span>
                                  </>
                                )
                            )}
                        </p>
                      </div>
                    </div>
                  </>
                )
            )}
          </StyledSlider>
        </Row>
      ))}
    </ProjectsContainer>
  );
};

export default ProjectsContent;
