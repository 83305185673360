import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/shared/Layout"
import ProjectsContent from "../components/projects/ProjectsContent"
import PageHeader from "../components/shared/PageHeader"

const Projects = ({ data }) => {
  return (
    <Layout
      seoTitle="Projects"
      seoKeywords="JFEM Projects, JFE Engineering Malaysia Projects, JFEM Services & Solution, JFE Engineering Malaysia Services/Solutions, EPC Company, Industrial Plant, Lubricant, Petrochemical, General Manufacturing, Storage Facilities, Receiving Facilities, Pipeline, Geothermal, Waste Management, WWTP, WTP, WHR"
    >
      <PageHeader
        pageTitle="Projects"
        pageDescription={data?.markdownRemark?.frontmatter?.header?.description}
      />
      <ProjectsContent />
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "projects" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
  }
`
